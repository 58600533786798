import LoginPage from './pages/login/LoginPage';
import HomePage from './pages/home/HomePage';
import RegisterPage from './pages/register/RegisterPage';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Vendas from './pages/home/vendas/vendas';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { SidebarProvider } from './hooks/sidebar.hook';
function App() {
  return (
    <BrowserRouter>
      <SidebarProvider>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/forgot' element={<ForgotPassword />} />
          <Route path='/vendas' element={<Vendas />} />
        </Routes>
      </SidebarProvider>
    </BrowserRouter>
  );
}

export default App;
