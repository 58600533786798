import React, { useState } from "react";
import { Link } from "react-router-dom";
import "boxicons/css/boxicons.min.css";
import "./sidebar.css";
import logohorizontalimg from "../../../assets/imagens/coloridahorizontal.png";
import { useDropdown } from "../../../hooks/sidebar.hook";
function Sidebar() {
  const [dropdownOpen, toggleDrop, closeSidebar, openAndCloseOther] = useDropdown();

  const toggleSidebar = () => {
    toggleDropdown("isOpen");
  };

  const toggleDropdown = (dropdown) => {
    toggleDrop(dropdown);
  };

  return (
    <>
      <nav className={dropdownOpen.isOpen ? "open" : ""}>
        <div className="logo" onClick={toggleSidebar}>
          <i className="bx bx-menu menu-icon"></i>
          <img src={logohorizontalimg} alt="logo" />
        </div>

        <div className="sidebar">
          <div className="logo" onClick={toggleSidebar}>
            <i className="bx bx-menu menu-icon"></i>
            <img src={logohorizontalimg} alt="logo" />
          </div>

          <div className="sidebar-content">
            <ul className="lists">
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("principal")}>
                  <i className="bx bx-home-alt icon"></i>
                  <Link className="txt-btn" to={"/home"}>
                    <span className="link">Página Principal</span>
                  </Link>
                  <i className={`bx "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
              </li>
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("vendas")}>
                  <i className="bx bx-money-withdraw icon"></i>
                  <span className="link">Vendas</span>
                  <i className={`bx ${dropdownOpen.vendas ? "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
                {dropdownOpen.vendas && (
                  <ul className="dropdown">
                    <Link className="txt-btn" to={"/vendas"}>
                      <li>Nova venda</li>
                    </Link>
                    <li>Histórico de venda</li>
                    {/*<li>Submenu 6</li>*/}
                  </ul>
                )}
              </li>
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("produtos")}>
                  <i className="bx bx-bell icon"></i>
                  <span className="link">Produtos</span>
                  <i className={`bx ${dropdownOpen.produtos ? "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
                {dropdownOpen.produtos && (
                  <ul className="dropdown">
                    <li>Cadastrar produtos</li>
                    <li>Lista de produtos</li>
                    {/*<li>Submenu 9</li>*/}
                  </ul>
                )}
              </li>
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("clientes")}>
                  <i className="bx bx-message-rounded icon"></i>
                  <span className="link">Clientes</span>
                  <i className={`bx ${dropdownOpen.clientes ? "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
                {dropdownOpen.clientes && (
                  <ul className="dropdown">
                    <li>Cadastrar clientes</li>
                    <li>Lista de clientes</li>
                    {/*<li>Submenu 12</li>)*/}
                  </ul>
                )}
              </li>
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("relatorios")}>
                  <i className="bx bx-pie-chart-alt-2 icon"></i>
                  <span className="link">Relatórios</span>
                  <i className={`bx ${dropdownOpen.relatorios ? "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
                {dropdownOpen.relatorios && (
                  <ul className="dropdown">
                    <li>Relatório financeiro</li>
                    <li>Relatório de vendas</li>
                    {/*  <li>Submenu 15</li> */}
                  </ul>
                )}
              </li>
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("financeiro")}>
                  <i className="bx bx-money-withdraw icon"></i>
                  <span className="link">Financeiro</span>
                  <i className={`bx ${dropdownOpen.financeiro ? "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
                {dropdownOpen.financeiro && (
                  <ul className="dropdown">
                    <li>Nova movimentação</li>
                    {/* <li>Submenu 17</li>
                    <li>Submenu 18</li> */}
                  </ul>
                )}
              </li>
              <li className="list">
                <div className="nav-link" onClick={() => toggleDropdown("fornecedores")}>
                  <i className="bx bx-folder-open icon"></i>
                  <span className="link">Fornecedores</span>
                  <i className={`bx ${dropdownOpen.fornecedores ? "bx-chevron-up" : "bx-chevron-down"}`}></i>
                </div>
                {dropdownOpen.fornecedores && (
                  <ul className="dropdown">
                    <li>Submenu 19</li>
                    <li>Submenu 20</li>
                    <li>Submenu 21</li>
                  </ul>
                )}
              </li>
            </ul>

            <div className="bottom-content">
              <li className="list">
                <div className="nav-link">
                  <i className="bx bx-cog icon"></i>
                  <span className="link">Configurações</span>
                </div>
              </li>
              <li className="list">
                <div className="nav-link">
                  <i className="bx bx-log-out icon"></i>
                  <span className="link">Sair</span>
                </div>
              </li>
            </div>
          </div>
        </div>
      </nav>

      {dropdownOpen.isOpen && <section className="overlay" onClick={closeSidebar}></section>}
    </>
  );
}

export default Sidebar;
