import coloridaimg from "../../assets/imagens/colorida.png";
import showimg from "../../assets/imagens/show.png";
import hideimg from "../../assets/imagens/hide.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./LoginPage.css";
function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() !== "" && password.trim() !== "") {
      const response = await fetch("https://facilgestao.dev.cpdntech.com.br/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          login: email,
          password: password,
        }),
      });
      const responseBody = await response.json();
      if (response.ok) {
        const acessToken = responseBody.accessToken;
        localStorage.setItem("userToken", acessToken);
        navigate("/home");
      } else {
        const errors = responseBody.errors;
        if (errors) {
          for (let index = 0; index < errors.length; index++) {
            const error = errors[index];
            document.getElementById("messageerror").textContent = error.message;
          }
        } else {
          alert("Erros desconhecido");
        }
      }
    }
  };

  const tooglePasswordHide = () => {
    console.log("tooglePasswordHide");
    setShowPassword(!showPassword);
  };

  return (
    <body translate="no">
      <div className="containter">
        <div class="hero">
          <div className="containter-login">
            <div className="wrap-login">
              <form className="login-form" onSubmit={handleSubmit}>
                <span className="login-form-title">Bem vindo! :)</span>

                <span className="login-form-title">
                  {" "}
                  <img src={coloridaimg}></img>
                </span>

                <div className="wrap-input">
                  <input
                    className={email !== "" ? "has-val input" : "input"}
                    required="required"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <span className="focus-input" data-placeholder="Email ou usurário"></span>
                </div>

                <div className="wrap-input">
                  <input
                    className={password !== "" ? "has-val input" : "input"}
                    required="required"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>
                  <span className="focus-input" data-placeholder="Senha"></span>
                  <img id="icon" src={showPassword ? showimg : hideimg} onClick={tooglePasswordHide}></img>
                </div>

                <div>
                  <p id="messageerror"></p>
                </div>

                <div className="containter-login-form-btn">
                  <button type="submit" className="login-form-btn">
                    Conecte-se
                  </button>
                </div>

                <div className="options">
                  <div className="custom-checkbox">
                    <input id="checkbox-1" type="checkbox" />
                    <label htmlFor="checkbox-1"> Manter conectado?</label>
                  </div>

                  <div>
                    <Link className="forgot-txt" to={"./Forgot"}>
                      <a className="forgot">Esqueceu a senha?</a>
                    </Link>
                  </div>
                </div>

                <div className="text-center">
                  <span className="txt1">Não possui conta?</span>
                  <Link className="txt2-link" to={"./Register"}>
                    <a className="txt2">Criar conta</a>
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
        </div>
      </div>
    </body>
  );
}

export default LoginPage;
