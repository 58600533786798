import coloridaimg from '../../assets/imagens/colorida.png'
import showimg from '../../assets/imagens/show.png'
import hideimg from '../../assets/imagens/hide.png'
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import "./ForgotPassword.css"
function ForgotPassword(){

const [email, setEmail] = useState("");

const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() !== '') {
      navigate('/home');
    }
  }
  
    return(
  <body>
    <div className="containter">
      <div class="hero"> 
      <div className="containter-register">
        <div className="wrap-register">

          <form className="login-form" onSubmit={handleSubmit}>

            <span className="login-form-title">Esqueci <br></br> minha senha! :(</span>
            <span className="login-form-title"><img src={coloridaimg}></img>
            </span>

            <span className='text-recover'>Para redefinir sua senha, informe o usuário ou e-mail cadastrado na sua conta e lhe enviaremos um link com instruções.</span>

            <div className="all-inputs">
            <div className='all-inputs-row-2'>
            <div className='wrap-input'>
              <input className={email !== "" ? 'has-val input' : 'input'} required 
              type='email' value={email} 
              onChange={e => setEmail(e.target.value)}>
              </input>
              <span id='placeholder-end' className='focus-input' data-placeholder='Email' >
              </span>
            </div>
            </div>
            </div>
            
            <div className='containter-register-form-btn'>
              <button className='register-form-btn' type='submit'>Enviar</button>
            </div>
            
            <div className='text-center'>
              <Link className='txt2-link' to={"/"}>
              <a className='txt2'>Ir para login</a>
              </Link>
            </div>         
          </form>
        </div>
      </div>  
         <div class="cube"></div>
         <div class="cube"></div>
         <div class="cube"></div>
         <div class="cube"></div>
         <div class="cube"></div>
         <div class="cube"></div>
      </div>
    </div>
    </body>
    )
}

export default ForgotPassword;