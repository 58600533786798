// hooks/sidebar-hook.js
import { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [showDropdown, setShowDropdown] = useState({
    principal: false,
    vendas: false,
    produtos: false,
    clientes: false,
    relatorios: false,
    financeiro: false,
    fornecedores: false,
    isOpen: false,
  });

  const toggleDropdown = (dropdown) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };
  const toggleAndCloseOther = (dropdown) => {
    setShowDropdown((prevState) => ({
      isOpen: prevState.isOpen,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeSidebar = () => {
    setShowDropdown((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  return (
    <SidebarContext.Provider value={[showDropdown, toggleDropdown, closeSidebar, toggleAndCloseOther]}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useDropdown = () => useContext(SidebarContext);
