import coloridaimg from "../../assets/imagens/colorida.png";
import showimg from "../../assets/imagens/show.png";
import hideimg from "../../assets/imagens/hide.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./RegisterPage.css";
function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [nameCompany, setNameCompany] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    console.log("adasdsadasd");

    e.preventDefault();
    if (email.trim() !== "" && password.trim() !== "" && name.trim() !== "" && nameCompany.trim() !== "") {
      const response = await fetch("https://facilgestao.dev.cpdntech.com.br/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          companyName: nameCompany,
          ownerName: name,
        }),
      });
      const responseBody = await response.json();
      if (response.ok) {
        setTimeout(() => {
          document.getElementById("messageerror").textContent = "Usuario criado com sucesso";
          navigate("/");
        }, 1000);
      } else {
        const errors = responseBody.errors;
        if (errors) {
          for (let index = 0; index < errors.length; index++) {
            const error = errors[index];
            document.getElementById("messageerror").textContent = error.message;
          }
        } else {
          document.getElementById("messageerror").textContent = "Erro desconhecido";
        }
      }
    }
  };
  const tooglePasswordHide = () => {
    console.log("tooglePasswordHide");
    setShowPassword(!showPassword);
  };

  return (
    <body>
      <div className="containter">
        <div class="hero">
          <div className="containter-register">
            <div className="wrap-register">
              <form className="login-form" onSubmit={handleSubmit}>
                <span className="login-form-title">Cadastro! :)</span>

                <span className="login-form-title">
                  <img src={coloridaimg}></img>
                </span>

                <div className="all-inputs">
                  <div className="all-inputs-row-1">
                    <div className="wrap-input">
                      <input
                        className={name !== "" ? "has-val input" : "input"}
                        required
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></input>
                      <span className="focus-input" data-placeholder="Nome do proprietário"></span>
                    </div>

                    <div className="wrap-input">
                      <input
                        className={nameCompany !== "" ? "has-val input" : "input"}
                        required
                        type="text"
                        value={nameCompany}
                        onChange={(e) => setNameCompany(e.target.value)}
                      ></input>
                      <span className="focus-input" data-placeholder="Nome da empresa"></span>
                    </div>
                  </div>

                  <div className="all-inputs-row-2">
                    <div className="wrap-input">
                      <input
                        className={email !== "" ? "has-val input" : "input"}
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                      <span id="placeholder-end" className="focus-input" data-placeholder="Email"></span>
                    </div>

                    <div className="wrap-input">
                      <input
                        className={password !== "" ? "has-val input" : "input"}
                        required
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                      <span className="focus-input" data-placeholder="Senha"></span>
                      <img id="icon" src={showPassword ? showimg : hideimg} onClick={tooglePasswordHide}></img>
                    </div>
                  </div>
                </div>

                <div>
                  <p id="messageerror"></p>
                </div>

                <div className="containter-register-form-btn">
                  <button className="register-form-btn" type="submit">
                    cadastrar
                  </button>
                </div>

                <div className="text-center">
                  <span className="txt1">Já possui uma conta?</span>
                  <Link className="txt2-link" to={"/"}>
                    <a className="txt2">Ir para login</a>
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
          <div class="cube"></div>
        </div>
      </div>
    </body>
  );
}

export default RegisterPage;
